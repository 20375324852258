<template>
  <div class="document-view">
    <v-card>
      <v-card
        v-for="cid in doc.cards"
        v-bind:key="cid"
        shaped
        tile
        :style="{
          'border-left-color': getColorByType(getTypeConfig(cid).type),
        }"
        class="list-card"
      >
        <v-card-text>
          <v-row>
            <!--
            <v-col
              :cols="showComments ? 2 : 3"
              class="text-caption text-uppercase"
            >
              {{ $t("columns." + getTypeConfig(cid).type).title }}
            </v-col>
          -->
            <v-col :cols="showComments ? 7 : 12">
              <v-row>
                <v-col
                  cols="12"
                  class="text-caption text-uppercase font-weight-black"
                  >{{ $t("columns." + getTypeConfig(cid).type).title }} /
                  {{ getObject(cid).title }}</v-col
                >
              </v-row>
              <v-row>
                <!--
                <v-col
                  cols="12"
                  class="text-caption font-weight-black text-uppercase"
                  >{{ getObject(cid).title }}</v-col
                > -->
                <v-col cols="12">
                  <document-view-item-editorjs
                    :card="getObject(cid)"
                    v-if="editorName === 'editorjs'"
                    :class="{ 'no-comments': showComments ? false : true }"
                  />
                </v-col>
                <v-col cols="12" class="text-caption text-uppercase">
                  <TagsPlate :tags="getObject(cid).data.tags" />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="5"
              v-if="showComments"
              style="border-left: 1px dashed rgb(0, 0, 0, 0.2)"
            >
              <v-row cols="12">
                <!--
                <v-col cols="12">
                  <v-row
                    cols="12"
                    v-for="comment in comments[cid].data"
                    v-bind:key="comment.id"
                  >
                    <v-col cols="5" class="text-caption">
                      {{ new Date(comment.createdAt).toLocaleString() }}<br />
                      {{ users[comment.createdBy] }}
                    </v-col>
                    <v-col cols="5">{{ comment.text }}</v-col>
                    <v-col cols="2">
                      <div v-if="user.id === comment.createdBy">
                        <v-btn icon @click="deleteComment(comment.id)">
                          <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-col> -->
                <v-card
                  width="100%"
                  tile
                  class="mx-auto"
                  flat
                  v-if="showComments"
                >
                  <v-card-text>
                    <template v-if="comments[cid]">
                      <v-list
                        dense
                        class="pa-0 ma-0"
                        style="overflow: scroll"
                        v-bind:key="comments[cid].key"
                      >
                        <v-list-item
                          v-for="comment in comments[cid].data"
                          v-bind:key="comment.id"
                          dense
                        >
                          <v-list-item-content>
                            <v-list-item-subtitle
                              >{{
                                new Date(comment.createdAt).toLocaleString()
                              }}
                              |
                              {{
                                users[comment.createdBy].name
                              }}</v-list-item-subtitle
                            >
                            <v-list-item-title
                              class="comment-body"
                              v-html="comment.text"
                            />
                          </v-list-item-content>
                          <v-list-item-action class="d-print-none">
                            <v-btn
                              v-if="user.id === comment.createdBy"
                              icon
                              @click="deleteComment(comment.id)"
                              small
                            >
                              <v-icon small>mdi-delete</v-icon>
                            </v-btn></v-list-item-action
                          >
                        </v-list-item>
                      </v-list>
                    </template>
                    <v-list-item dense class="d-print-none">
                      <v-list-item-content></v-list-item-content>
                      <v-list-item-action>
                        <CommentWithButton
                          :projectId="project._id"
                          :sectionId="cid"
                          v-on:refresh="loadComments"
                        />
                      </v-list-item-action>
                    </v-list-item>
                  </v-card-text>
                </v-card>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card tile class="mx-auto" flat v-if="showComments">
        <v-divider />
        <v-card-title class="text-caption text-uppercase"
          >{{ $t("learningDesign.feedback") }}
          <span class="d-print-none">
            <CommentWithButton
              :projectId="project._id"
              v-on:refresh="loadComments" /></span
        ></v-card-title>
        <v-card-text>
          <template v-if="comments[projectId]">
            <v-list
              dense
              class="pa-0 ma-0"
              style="overflow: scroll"
              v-bind:key="comments[projectId].key"
            >
              <v-list-item
                v-for="comment in comments[projectId].data"
                v-bind:key="comment.id"
                dense
              >
                <v-list-item-content>
                  <v-list-item-subtitle
                    >{{ new Date(comment.createdAt).toLocaleString() }}
                    |
                    {{ users[comment.createdBy].name }}</v-list-item-subtitle
                  >
                  <v-list-item-title
                    class="comment-body"
                    v-html="comment.text"
                  />
                </v-list-item-content>
                <v-list-item-action class="d-print-none">
                  <v-btn
                    v-if="user.id === comment.createdBy"
                    icon
                    @click="deleteComment(comment.id)"
                    small
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn></v-list-item-action
                >
              </v-list-item>
            </v-list>
          </template>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import DocumentViewItemEditorjs from "./DocumentViewItemEditorJS.vue";
import CommentWithButton from "./CommentWithButton.vue";
import { mdiCommentMultiple } from "@mdi/js";
import { mapGetters } from "vuex";
import TagsPlate from "../TagsPlate.vue";

export default {
  name: "DocumentView",
  components: {
    DocumentViewItemEditorjs,
    CommentWithButton,
    TagsPlate,
  },
  props: {
    projectId: String,
  },
  data: () => ({
    comments: {},
    rootComments: [],
    users: {},
    icons: {
      mdiCommentMultiple,
    },
    projects: {},
    doc: {},
    editorName: false,
  }),
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "DESIGN_SCHEME",
      "project",
      "user",
      "showComments",
      "sortComments",
    ]),
  },
  watch: {
    sortComments() {
      this.loadComments();
    },
  },
  mounted() {
    if (typeof this.project.data.lists === "undefined")
      this.project.data.lists = [];
    if (typeof this.project.data.objects === "undefined")
      this.project.data.objects = [];
    this.doc = this.project.data.lists.filter(
      (l) => l.extras.id === "teaching-design-lists"
    )[0];
    this.loadComments();
    this.editorName = this.project?.data?.config?.editor || "editorjs";
  },
  methods: {
    getColorByType(type) {
      // (this.DESIGN_SCHEME, type);
      return this.DESIGN_SCHEME.colors[type] || "#a9a9a8";
    },
    deleteComment(cid) {
      this.$api
        .patch(`/fragments/${this.project._id}/comments/${cid}/archive`)
        .catch(() => {})
        .finally(() => {
          this.loadComments();
        });
    },
    async loadComments() {
      const res = await this.$api.get(
        `/fragments/${this.project._id}/comments`
      );
      const commentsRes = res?.data?.fragments?.comment || [];

      const comments = {};
      const users = {};
      const sortFnc = this.sortComments ? "push" : "unshift";

      commentsRes.forEach((comment) => {
        const cid = comment.parentFragmentId;
        users[comment.createdById] = {
          id: comment.createdById,
          name:
            comment?.createdBy?.name ||
            comment?.createdBy?.email ||
            comment.createdById,
        };
        if (typeof comments[cid] === "undefined") {
          comments[cid] = {
            key: Math.random(),
            data: [],
          };
        }
        comments[cid].data[sortFnc]({
          id: comment.id,
          createdAt: comment.createdAt,
          text: comment?.data?.body || "",
          createdBy: comment.createdById,
        });
      });
      this.comments = comments;
      this.users = users;
      this.$forceUpdate();
    },
    getTypeConfig(id) {
      const object = this.getObject(id);
      const type = this.project.data.lists.filter(
        (l) => l.type === object?.type
      )[0];

      return type;
    },
    getObject(id) {
      const object = this.project.data.objects.filter((o) => o.id === id)[0];

      return object || {};
    },
    // eslint-disable-next-line no-unused-vars
    getItemType(id) {},
  },
};
</script>
<style scoped>
.list-card {
  border-left-width: 4px;
  border-left-style: solid;
  border-top-left-radius: 4px 4px !important;
  border-bottom-left-radius: 4px 4px !important;
}
.v-list-item__title {
  white-space: normal;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb:vertical {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.comment-body {
  white-space: pre-line;
}

.document-view {
  position: absolute;
  top: 6px;
  right: 12px;
  bottom: 6px;
  left: 6px;
  padding: 6px;
  overflow: auto;
}

@media print {
  .document-view {
    position: static;
    overflow: visible;
  }
  img {
    break-before: avoid !important; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    break-after: avoid !important; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    break-inside: avoid !important; /* or 'auto' */
    display: block !important;
  }
}
</style>
