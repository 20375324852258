<template>
  <v-dialog
    v-model="show"
    scrollable
    :transition="false"
    width="90%"
    max-width="900px"
    persistent
  >
    <v-card style="border: 16px solid white">
      <v-toolbar flat>
        <v-row>
          <v-col cols="10">
            <v-text-field
              :placeholder="$t('card.titlePlaceHolder')"
              v-model="card.title"
              :label="groupName"
              :rules="titleRules"
              hide-details="auto"
              append-icon="mdi-pencil"
              dense
            />
          </v-col>
          <v-col cols="2" align="right" class="pr-0">
            <!--
            <v-btn x-small color="orange" fab @click="closeEditor">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          -->
            <ConfirmationButton
              :name="$t('card.cancel')"
              :name-confirm="$t('buttons.confirm')"
              :name-decline="$t('buttons.decline')"
              color="red"
              small
              @confirm="closeEditor()"
            />
          </v-col>
        </v-row>
      </v-toolbar>
      <v-card-text class="pa-3">
        <v-row>
          <v-col cols="10" v-if="canTagsEdit">
            <multiselect
              v-model="tags"
              tag-placeholder="Add this as new tag"
              placeholder="Add a tag"
              label="name"
              color="red"
              track-by="code"
              :options="tagsSource"
              :multiple="true"
              :taggable="true"
              @tag="addTag"
              :showNoOptions="false"
            ></multiselect>
          </v-col>
          <v-col cols="2" v-if="canTagsEdit"></v-col>
          <v-col cols="1">&nbsp;</v-col>
          <v-col :cols="comments.length === 0 ? 10 : 6">
            <CardEditorEditorJS
              v-if="editorName === 'editorjs'"
              :card="card"
              :projectId="projectId"
              :project="project"
            />
            <CardEditorTipTap
              v-if="editorName === 'tiptap'"
              :card="card"
              :projectId="projectId"
              :project="project"
            />
          </v-col>
          <v-col v-if="comments.length > 0" cols="4">
            <template v-if="comments.length > 0">
              <v-list dense class="pa-0 ma-0">
                <v-list-item
                  v-for="comment in comments"
                  v-bind:key="comment.id"
                  dense
                >
                  <v-list-item-content>
                    <v-list-item-subtitle
                      >{{ new Date(comment.createdAt).toLocaleString() }}<br />
                      {{ users[comment.createdBy] }}</v-list-item-subtitle
                    >
                    <v-list-item-title
                      ><br />{{ comment.text }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template></v-col
          >
          <v-col cols="1">&nbsp;</v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-card
          v-if="card.type && $t('columns.' + card.type).tooltips"
          tile
          max-width="350px"
          class="text-caption pa-2"
        >
          <ul>
            <li
              v-for="(tip, i) in $t('columns.' + card.type).tooltips"
              :key="i"
            >
              {{ tip }}
            </li>
          </ul>
        </v-card>
        <v-spacer />
        <ConfirmationButton
          :name="$t('card.delete')"
          :name-confirm="$t('buttons.confirm')"
          :name-decline="$t('buttons.decline')"
          color="red"
          colorConfirm="red"
          colorDecline="primary"
          small
          @confirm="deleteCard"
          v-if="!fixed"
          btnClass="mr-4"
        />
        <v-btn small color="primary" @click="saveCard">Save</v-btn>
        <!--
        <ConfirmationButton
          :name="$t('card.save')"
          :name-confirm="$t('buttons.confirm')"
          :name-decline="$t('buttons.decline')"
          color="primary"
          colorConfirm="primary"
          colorDecline="red"
          small
          @confirm="saveCard"
        /> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */

import { mapGetters } from "vuex";
import CardEditorEditorJS from "./CardEditorEditorJS.vue";
import CardEditorTipTap from "./CardEditorTipTap.vue";
import Multiselect from "vue-multiselect";
import ConfirmationButton from "../ConfirmationButton.vue";

function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export default {
  name: "CardEditor",
  prev: {},
  components: {
    CardEditorEditorJS,
    CardEditorTipTap,
    Multiselect,
    ConfirmationButton,
  },
  data: () => ({
    show: true,
    editorName: "undefined",
    projectId: false,
    itemId: "",
    listId: "",
    card: {},
    fixed: false,
    saveDisabled: false,
    group: {},
    groupName: "",
    titleRules: [
      (value) => !!value || "Required.",
      (value) => (value && value.length >= 1) || "Min 1 characters",
    ],
    TITLE_PLACEHOLDER: "Skriv inn navnet ditt...",
    comments: [],
    users: {},
    canTagsEdit: false,
    tags: [],
    tagsSource: [],
  }),
  computed: {
    ...mapGetters(["projects", "project", "sortComments", "showComments"]),
  },
  watch: {
    // eslint-disable-next-line func-names
    "card.title": function (val) {
      if (val.trim().length < 1) {
        this.saveDisabled = true;
      } else {
        this.saveDisabled = false;
      }
    },
    show(val) {
      if (val === false) {
        const projectId = this.$route.params.id;
        this.$router.replace({ name: "Project", id: projectId });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      try {
        this.projectId = this.project._id;
        this.itemId = this.$route.params.item;

        const cards = clone(
          (this.project?.data.objects || []).filter(
            (e) => e.id === this.itemId && !e.archived
          )
        );

        this.card = cards.length > 0 ? cards[0] : {};

        const groups = (this.project?.data.lists || []).filter(
          (e) => e.type === this.card.type // e.cards.indexOf(this?.card?.id) !== -1
        );
        this.group = groups.length > 0 ? groups[0] : null;

        if (
          !this?.card?.id ||
          !this?.group?.id
          // && this?.card?.type !== "teaching-design"
        ) {
          this.$router.push({ name: "Projects" });
          return;
        }

        this.groupName = this.$t(
          "columns." + (this?.group?.type || this?.card?.type)
        ).title;
        this.listId = this?.group?.id || false;

        // this.editorName =
        //  this.card.type === "adrbeidsmater" ? "editorjs" : "tiptap";

        this.editorName = "editorjs";

        this.fixed =
          this?.card?.fixed ||
          this?.card?.extras?.fixed ||
          this?.card?.type === "teaching-design";

        if (this.fixed && this?.card?.type === "teaching-design") {
          this.canTagsEdit = true;
        }
        if (this.canTagsEdit) {
          this.tags = (this?.card?.data?.tags || []).map((name) => ({
            name,
            code: name.substring(0, 2) + Math.floor(Math.random() * 10000000),
          }));
          this.tagsSource = this.tags.map((tag) => ({
            name: tag.name,
            code: tag.code,
          }));
        }
      } catch (error) {
        this.$router.replace({ name: "Projects" });
        return;
      }
      if (this.showComments) this.loadComments();
    });
  },
  methods: {
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.tagsSource.push(tag);
      this.tags.push(tag);
    },
    closeEditor() {
      this.show = false;
      // const projectId = this.$route.params.id;
      // this.$router.replace({ name: "Project", id: projectId });
    },
    async deleteCard() {
      const self = this;
      self.project.data.lists.forEach((list) => {
        list.cards = list.cards.filter((id) => id !== this.itemId);
      });
      self.project.data.objects = self.project.data.objects.filter(
        (e) => e.id !== this.itemId
      );
      await this.$store.dispatch("deleteCard", { id: this.itemId });
      this.$nextTick(function () {
        self.$router.replace({ name: "Project", id: self.projectId });
      });
    },
    saveCard() {
      const self = this;
      const mapper =
        self.project.data.objects.filter((e) => e.id === self.card.id)[0] || {};
      Object.assign(mapper, self.card);
      const tags = this.tags.map((tag) => tag.name);
      this.$store.dispatch("updateCard", {
        id: mapper.id,
        title: mapper.title,
        type: mapper?.type,
        tags,
        body: JSON.stringify(mapper.content),
      });
      this.closeEditor();
    },
    async loadComments() {
      const self = this;
      const res = await this.$api.get(`/fragments/${this.project.id}/comments`);
      const commentsRes = res?.data?.fragments?.comment || [];

      const comments = [];
      const users = {};
      const sortFnc = this.sortComments ? "push" : "unshift";

      commentsRes.forEach((comment) => {
        const cid = comment.parentFragmentId;
        if (cid !== self.card.id) return;
        users[comment.createdById] = comment.createdById;
        comments[sortFnc]({
          id: comment.id,
          createdAt: comment.createdAt,
          text: comment?.data?.body || "",
          createdBy: comment.createdById,
        });
      });
      this.comments = comments;
      this.users = users;
      this.$forceUpdate();
    },
  },
};
</script>
<style scoped>
.input-card {
  position: relative;
  min-height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  margin: 4px 8px;
  word-break: break-all;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans,
    Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
}
.v-list-item__title {
  white-space: normal;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb:vertical {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tag {
  background-color: #add8e6;
  color: rgba(0, 0, 0, 0.87);
}
</style>
